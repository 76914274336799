<template>
<div>
  <rel-header>...</rel-header>
</div>
</template>

<script>
import RelHeader from '@/components/relatorios/layout/RelHeader'
export default {
  name: "Visualizacao",
  components: {RelHeader}
}
</script>
